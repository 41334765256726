/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Export from './export';
import Summary from './summary';
import {WorkOrdersActions} from './work-orders-actions';
import WorkOrdersStore from './work-orders-store';
import RouteValidator from '../../common/custom-validator/route/route';
import DocumentTitle from '../../common/document-title';
import {MODAL_TYPE} from '../../common/notification/modal';
import {NotificationActions} from '../../common/notification/notification-actions';
import {Tab, Tabs} from '../../common/routing-tab/routing-tab';
import {History, SideNotes} from '../../common/side-notes/side-notes';
import {AlertsWarnings} from '../../common/validations/alerts-warnings';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Preloader from '../../preloader';
import PreloaderStore from '../../preloader/preloader-store';
import {RouterActions} from '../../router/router-actions';
import SessionStore from '../../user/session/session-store';

class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            route: RouteValidator
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            route: undefined
        };
    }

    static getPermissions() {
        return {
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.WORK_ORDERS.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.WORK_ORDERS.EDIT),
            canReadTitles: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.READ)
        };
    }

    static calculateState() {
        return {
            originalWorkOrder: WorkOrdersStore.getState().get('originalWorkOrder'),
            originalWorkOrderTitles: WorkOrdersStore.getState().get('originalWorkOrderTitles'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            recipientUser: WorkOrdersStore.getState().get('recipientUser'),
            workOrder: WorkOrdersStore.getState().get('workOrder'),
            workOrderTitles: WorkOrdersStore.getState().get('workOrderTitles')
        };
    }

    static getStores() {
        return [PreloaderStore, WorkOrdersStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activeTab:0,
            notes: Immutable.List()
        }, this.constructor.calculateState());

        this.handleCancel = this.handleCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
    }

    componentDidMount() {
        this.init(this.props);
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
        return;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.route.mode !== nextProps.route.mode ||
            this.props.params.id !== nextProps.params.id
        ) {
            this.init(nextProps);
        }
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
    }

    componentWillUnmount() {
        RouterActions.clearRedirectChecks();
    }


    handleCancel() {
        RouterActions.redirect('/titles/work-orders', true);
    }


    handleDelete() {
        WorkOrdersActions.remove(this.props.params.id);
        return;
    }

    handleSave() {
        WorkOrdersActions.save(this.state.workOrder, this.state.workOrderTitles);
    }

    init(props) {
        if (props.route.mode === 'edit') {
            WorkOrdersActions.findById(props.params.id);
        } else {
            WorkOrdersActions.clear();
        }
    }

    /* istanbul ignore next */
    isDirty(location) {
        if (this.props.route.mode === 'create') {
            return false;
        }
        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && /* titles */
                goingTo[2] === current[2] && /* work-order */
                goingTo[3] === current[3]) /* id */ {
                return false;
            }
        }
        return !this.state.originalWorkOrder.equals(this.state.workOrder) || !this.state.originalWorkOrderTitles.equals(this.state.workOrderTitles);
    }

    showDeleteDialog(event) {
        event.preventDefault();

        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['titles.work-orders.edit.confirm-delete.title'],
            this.context.intl.messages['titles.work-orders.edit.confirm-delete.body'],
            this.context.intl.messages['titles.work-orders.edit.confirm-delete.confirm'],
            this.handleDelete
        );
    }

    render() {
        let actionsMenu;
        let disableForm = !this.props.permissions.canEdit;
        let mode = this.props.route.mode;
        let historyTab = null;
        let eventsAndNotes = null;
        let pageTitle = this.context.intl.messages['titles.work-orders.create.title'];
        let pageDescription;
        let validations = WorkOrdersStore.getValidations();

        if (mode === 'create') {
            disableForm = false;

            actionsMenu = <ActionsMenu
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
            />;
        } else {
            actionsMenu = <ActionsMenu
                canEdit={this.props.permissions.canEdit}
                canDelete={this.props.permissions.canEdit}
                canSave={!validations.length && this.props.permissions.canEdit}
                onCancel={this.handleCancel}
                onDelete={this.showDeleteDialog}
                onSave={this.handleSave}
            />;

            pageTitle = this.context.intl.messages['titles.work-orders.edit.title'];
            pageDescription = this.state.workOrder.get('name');
            // TODO: validate basePath when server api supports action history for work orders
            eventsAndNotes = (
                <SideNotes
                    basePath={`work-order/${this.props.params.id}`}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={true/* !this.props.permissions.canEdit */}
                    notes={this.state.notes.toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );

            historyTab = <Tab route={`/titles/work-orders/${this.props.params.id}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <h3>{this.context.intl.messages['common.tab-title.history']}</h3>
                <History notes={this.state.notes.toJS()}/>
            </Tab>;
        }

        let baseRoute = '/titles/work-orders/create';
        if (mode === 'edit') {
            baseRoute = `/titles/work-orders/${this.props.params.id}`;
        }
        let summaryTab = <Tab route={baseRoute} title={this.context.intl.messages['titles.work-orders.create.summary-tab-title']}>
            <Summary
                disabled={disableForm}
                workOrder={this.state.workOrder}
                recipientUser={this.state.recipientUser}
                mode={this.props.route.mode}
            />
        </Tab>;
        let exportTab = <Tab route={`${baseRoute}/export`} title={this.context.intl.messages['titles.work-orders.export.title']}>
            <Export
                canReadTitles={this.props.permissions.canReadTitles}
                location={this.props.location}
                disabled={disableForm}
                workOrder={this.state.workOrder}
                workOrderTitles={this.state.workOrderTitles}
            />
        </Tab>;

        return (
            <DocumentTitle
                message={`document-titles.${mode}-workorder`} entityName={pageDescription}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="far fa-newspaper"></i>
                                &nbsp;{pageTitle}&nbsp;
                                <small>{pageDescription}</small>
                            </h1>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {exportTab}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <AlertsWarnings
                                        title={this.context.intl.messages['common.alerts-warnings-title']}
                                        validations={validations}
                                    />
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(Create);
