/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';

import {UserCompanyActions} from './user-company-actions';
import UserCompanyStore from './user-company-store';
import {Debounce} from '../../common/utils/utils';

class UserCompanySelect extends Component {
    static get propTypes() {
        return {
            allowCreate: PropTypes.bool,
            disabled: PropTypes.bool,
            labelKey: PropTypes.string,
            multi: PropTypes.bool,
            onChange: PropTypes.func.isRequired,
            placeholder: PropTypes.string,
            selected: PropTypes.object
        };
    }

    static get defaultProps() {
        return {
            allowCreate: false,
            disabled: true,
            labelKey: '',
            multi: false,
            placeholder: undefined,
            selected: undefined,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            companies: UserCompanyStore.getState().get('companies')
        };
    }

    static getStores() {
        return [UserCompanyStore];
    }

    constructor(props) {
        super(props);

        this.handleInputChange = Debounce(this.handleInputChange.bind(this), 200);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        // this is equals to UserCompanyActions.get(undefined, 0, 20)
        UserCompanyActions.get();
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            nextProps.selected !== this.props.selected ||
            nextProps.placeholder !== this.props.placeholder
        ) {
            return true;
        }
        if (nextState.companies !== this.state.companies) {
            return true;
        }

        return false;
    }

    handleInputChange(value) {
        UserCompanyActions.get(value, 0, 20);
        return;
    }

    onChange(company) {
        let isNew = false;

        if (company) {
            isNew = company.isNew;
        }

        this.props.onChange(company, isNew);
    }

    render() {
        let selected;
        if (this.props.multi) {
            if (this.props.selected) {
                selected = this.props.selected.toJS();
            } else {
                selected = '';
            }
        } else {
            if (this.props.selected) {
                selected = this.props.selected;
            } else {
                selected = '';
            }
        }

        let placeholder = this.context.intl.messages['common.user-company-select'];
        if (this.props.placeholder) {
            placeholder = this.props.placeholder;
        }

        if (this.props.allowCreate) {
            return (
                <div style={{width: '100%'}}>
                    <CreatableSelect
                        isValidNewOption={() => true}
                        isClearable={true}
                        isMulti={this.props.multi}
                        isDisabled={this.props.disabled}
                        name="companies"
                        options={this.state.companies.toJS()}
                        value={selected}
                        {...this.props}
                        placeholder={placeholder}
                        onChange={this.onChange}
                        onInputChange={this.handleInputChange}
                        getNewOptionData={(inputValue) => {
                            let newItem = {isNew:true, id:inputValue};
                            if (this.props.labelKey) {
                                newItem[this.props.labelKey] = inputValue;
                            }
                            return newItem;
                        }}
                    />
                </div>
            );
        }
        return (
            <Select
                isClearable={true}
                isMulti={this.props.multi}
                isDisabled={this.props.disabled}
                name="companies"
                onInputChange={this.handleInputChange}
                options={this.state.companies.toJS()}
                value={selected}
                {...this.props}
                placeholder={placeholder}
                onChange={this.onChange}
            />
        );
    }
}

export {UserCompanySelect as UserCompanySelect_BASE};
export default Container.create(UserCompanySelect);
