//istanbul ignore file
/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {AbrPlayer} from '@accurate-player/accurate-player-abr';
import {HotkeyPlugin} from '@accurate-player/accurate-player-plugins';
import React from 'react';
import {ResponsiveEmbed} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import {Subscription} from 'rxjs';

import {ChangeSyncTimeToggler, PlayerSources$, IsSyncTime$, UpdateTime, UpdateVideoDuration} from '../bl';
import {ChangeVttCueSource$, TimeUpdateSource$} from '../bl/sources';
import CCEditorActions from '../cc-editor-actions';
import {CreateRef} from '../helpers/create-ref';
import {ConvertCues} from '../helpers/cues';
import FetchCues from '../helpers/fetch-cues';
import {HideCCButton} from '../helpers/hide-cc-button';


import {NotificationActions} from '~/src/common/notification/notification-actions';
import WithRxSubscriptions from '~/src/decorators/with-rx-subscriptions';
import AccuratePlayer from '~/src/player/accurate-player';

type Props = {
    handlePlayerInit: (player: AbrPlayer) => void,
    isSyncTime: boolean,
    source: ImmutableMap<PlayerSource> | null,
    video: ImmutableMap<PlayableVideoAsset>,
    vttExternalUrl: string | null | undefined,
};

class CCEditorPlayer extends React.PureComponent<Props> {

    constructor(props: Props) {
        super(props);

        this.changeCurrentTime = this.changeCurrentTime.bind(this);
        this.handleCommitEvent = this.handleCommitEvent.bind(this);
        this.handleTimeUpdateEvent = this.handleTimeUpdateEvent.bind(this);
    }

    componentDidMount() {
        const ap = this.ap.current;
        if (!ap) {
            console.error('AccuratePlayer is not initialized properly');
            return;
        }

        this.props.handlePlayerInit(ap);
        this.fetchCues();

        ap.api.master.addEventListener('durationchange', () => {
            UpdateVideoDuration(ap.master.duration);
        }, {once: true});

        ap.api.master.addEventListener('timeupdate', this.handleTimeUpdateEvent);

        setTimeout(HideCCButton, 0);
        this.timeUpdateSubscription = TimeUpdateSource$.subscribe(this.changeCurrentTime);

        ChangeVttCueSource$.subscribe(this.handleCommitEvent);

        ap.api.listPlugins().forEach((plugin) => {
            if (plugin instanceof HotkeyPlugin) {
                // disable up/dow hotkeys
                // we are using up/down for navigating between captions
                plugin.setHotkey('up', () => void 0, 'increase volume (disabled)');
                plugin.setHotkey('down', () => void 0, 'decrease volume (disabled)');
            }
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.vttExternalUrl !== this.props.vttExternalUrl) {
            this.fetchCues();
        }
    }

    componentWillUnmount() {
        const ap = this.ap.current;
        if (ap) {
            ap.api.master.removeEventListener('timeupdate', this.handleTimeUpdateEvent);
        }
        this.timeUpdateSubscription?.unsubscribe();
    }

    private ap = CreateRef<AbrPlayer>();
    private timeUpdateSubscription: null | Subscription = null;

    private handleTimeUpdateEvent() {
        if (this.ap.current) {
            UpdateTime(this.ap.current.master.currentTime);
        }
    }

    private async fetchCues() {
        const ap = this.ap.current;
        if (!ap || !this.props.vttExternalUrl) {
            return;
        }
        try {
            const cues = await FetchCues(ap.api.master, this.props.vttExternalUrl);
            if (cues) {
                CCEditorActions.initCues(ConvertCues(cues));
            }
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                NotificationActions.showAlertDanger(e.message);
            }
        }
    }

    private findActiveTextTrack() {
        const ap = this.ap.current;
        if (ap) {
            return Array.from(ap.master.textTracks).find(t => t.mode === 'showing');
        }
    }

    private handleCommitEvent(commit: CCEditorCommit) {
        const track = this.findActiveTextTrack();
        if (track && track.cues) {
            const cues = track.cues;
            switch (commit.type) {
            case 'change':
                changeCueWith(track, commit.newValue);
                break;
            case 'remove':
                commit.origin.forEach((cue) => {
                    const originCue = cues.getCueById(cue.id);
                    if (originCue) {
                        track.removeCue(originCue);
                    }
                });
            }

            // refresh current cue in player
            track.mode = 'hidden';
            track.mode ='showing';
        }
    }

    private changeCurrentTime(time: number) {
        if (!this.ap.current) {
            return;
        }
        this.ap.current.api.master.currentTime = time;
        this.ap.current.api.pause();
        // sometimes player doesn't trigger `timeupdate` event.
        // to avoid freezing effect call listener manually
        this.handleTimeUpdateEvent();
    }

    private handleSyncTimeChanged(ev: React.SyntheticEvent<HTMLInputElement>) {
        if (ev.target instanceof HTMLInputElement) {
            ChangeSyncTimeToggler(ev.target.checked);
        }
    }

    /*istanbul ignore next*/
    render() {
        return (
            <div className="cc-editor-player">
                <div style={{width: '100%'}}>
                    <ResponsiveEmbed a16by9>
                        <div>
                            <AccuratePlayer
                                playerRef={this.ap}
                                src={this.props.source}
                                video={this.props.video}
                            />
                        </div>
                    </ResponsiveEmbed>

                    <div className="text-center checkbox">
                        <label className="form-check-label">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={this.props.isSyncTime}
                                onChange={this.handleSyncTimeChanged}
                            />
                            &nbsp;
                            <FormattedMessage id="cc-editor.video-player.sync.label" />
                        </label>
                    </div>

                </div>
            </div>
        );
    }
}

export default WithRxSubscriptions(CCEditorPlayer, {
    isSyncTime: IsSyncTime$,
    source: PlayerSources$,
});
export {CCEditorPlayer as CCEditorPlayer_BASE};

export function changeCueWith(track: TextTrack, changedCues: ReadonlyArray<WBTVDCue>) {
    const cues = track.cues;
    if (!cues) {
        return;
    }
    changedCues.forEach((cue) => {
        let originCue = cues.getCueById(cue.id);
        if (!originCue) {
            originCue = new VTTCue(cue.startTime, cue.endTime, cue.text);
            track.addCue(originCue);
        }
        // TODO: check isNew prop
        if (originCue instanceof VTTCue) {
            originCue.startTime = cue.startTime;
            originCue.endTime = cue.endTime;
            originCue.text = cue.text;
            originCue.align = cue.align;
            originCue.snapToLines = cue.snapToLines;
            originCue.position = normalizeLineAndPositionSetting(cue.position);
            originCue.line = normalizeLineAndPositionSetting(cue.line);
        }
    });
}

export function normalizeLineAndPositionSetting(pos: LineAndPositionSetting): LineAndPositionSetting {
    const res = parseInt(pos.toString(), 10);
    if (isNaN(res)) {
        return pos;
    }
    return res;
}
