/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import FocusFieldFactory from '../../helpers/focus-field-factory';
import SetCaretPosition from '../../helpers/set-caret-position';

import omit from '~/src/common/utils/omit';

type Props = {
    max: number;
    onInput: (value: number) => void;
    value: number;
}

type State = {
    maxLength: number,
    value: string,
}

const ORIGIN_PROPS: Array<keyof Props> = [
    'max', 'onInput', 'value'
];

export default class NumberField extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        const maxLength = props.max.toString().length;
        this.state = {
            maxLength,
            value: numberAsString(props.value, maxLength),
        };

        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        this.focusNextNumberField = FocusFieldFactory('next', 0);
        this.focusPrevNumberField = FocusFieldFactory('prev', maxLength);
    }

    componentDidUpdate(prevProps: Props) {
        this.updateValueIfRequired(prevProps);
    }

    componentWillUnmount() {
        this.clearFocusTimeout();
    }

    private focusTimeout: NodeJS.Timeout | null = null;
    private focusedElm: HTMLInputElement | null = null;
    private focusNextNumberField: (el: HTMLInputElement) => void;
    private focusPrevNumberField: (el: HTMLInputElement) => void;

    private clearFocusTimeout() {
        if (this.focusTimeout) {
            clearTimeout(this.focusTimeout);
        }
    }

    /*istanbul ignore next*/
    private updateValueIfRequired(prevProps: Props) {
        const {props, state, focusedElm} = this;
        const {value: stateValue} = state;

        if (prevProps === props) {
            return;
        }

        const maxLength = props.max.toString().length;

        this.focusNextNumberField = FocusFieldFactory('next', 0);
        this.focusPrevNumberField = FocusFieldFactory('prev', maxLength);

        this.setState(() => ({maxLength}));

        let inputValue = stateValue;
        if (!isNil(props.value)) {
            inputValue = numberAsString(props.value, maxLength);
        }

        if (focusedElm === null && inputValue !== stateValue) {
            this.updateValue({inputValue, input: focusedElm, setCaretPosition: false});
        }
    }

    /*istanbul ignore next*/
    private onChange(e: React.FormEvent<HTMLInputElement>) {
        const el = e.target;
        if (!(el instanceof HTMLInputElement)) {
            return;
        }
        let inputValue = el.value;
        const start = getCurrentCaretPosition(el);
        const {maxLength} = this.state;
        if (inputValue.length > maxLength) {
            if (start === maxLength) {
                inputValue = inputValue.substr(0, maxLength);
            } else {
                inputValue = (inputValue.slice(0, start) + inputValue.slice(start + 1)).substr(0, maxLength);
            }
        }

        if (parseInt(inputValue, 10) > this.props.max) {
            inputValue = inputValue.substr(0, 1) + '0';
        }

        if (parseInt(inputValue, 10) > this.props.max) {
            this.updateValue({
                inputValue: this.state.value, input: el, setCaretPosition: true, caretPos: start - 1,
            });
            return;
        }

        this.updateValue({
            inputValue, input: el, setCaretPosition: true, caretPos: start,
        });

        if (start === maxLength) {
            this.focusNextNumberField(el);
        }
    }

    private normalizeNumber(num: number) {
        return Math.max(0, Math.min(num, this.props.max));
    }

    private updateCurrentValue(delta: number, el: HTMLInputElement) {
        const num = this.normalizeNumber(parseInt(this.state.value, 10) + delta);
        this.updateValue({
            inputValue: numberAsString(num, this.state.maxLength),
            input: el,
            setCaretPosition: true,
        });
    }

    /*istanbul ignore next*/
    private onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        const el = e.target;
        if (!(el instanceof HTMLInputElement)) {
            return;
        }
        const {key} = e;

        if (key === 'ArrowUp') {
            e.preventDefault();
            this.updateCurrentValue(1, el);
        } else if (key === 'ArrowDown') {
            e.preventDefault();
            this.updateCurrentValue(-1, el);
        } else if (key === 'ArrowLeft' && getCurrentCaretPosition(el) === 0) {
            this.focusPrevNumberField(el);
        } else if (key === 'ArrowRight' && getCurrentCaretPosition(el) === this.state.maxLength) {
            this.focusNextNumberField(el);
        } else if (key.length === 1 && isNaN(parseInt(key, 10))) {
            e.preventDefault();
        }
    }

    private onBlur() {
        this.focusedElm = null;
    }

    private onFocus(e: React.FormEvent<HTMLInputElement>) {
        if (e.target instanceof HTMLInputElement) {
            this.focusedElm = e.target;
        }
    }

    private updateValue(params: {
        inputValue: string,
        input?: HTMLInputElement | null,
        caretPos?: number,
        setCaretPosition: boolean,
    }) {
        const {input} = params;
        let {caretPos} = params;
        /*istanbul ignore next*/
        const inputValue = params.inputValue || input?.value || '';

        if (input) {
            if (params.setCaretPosition) {
                if (!caretPos) {
                    const currentCaretPosition = getCurrentCaretPosition(input);
                    input.value = inputValue;
                    caretPos = currentCaretPosition;
                }

                //set caret position
                SetCaretPosition(input, caretPos || 0, inputValue);
            } else {
                input.value = inputValue;
            }
        }

        //update state if value is changed
        /*istanbul ignore next*/
        if (inputValue !== this.state.value) {
            this.setState(/*istanbul ignore next*/() => ({value: inputValue}));

            if (this.focusedElm) {
                const n = parseInt(inputValue, 10);
                if (isNaN(n)) {
                    this.props.onInput(0);
                } else {
                    this.props.onInput(n);
                }
            }
        }
    }

    render() {
        return (
            <input
                {...omit(this.props, ORIGIN_PROPS)}
                className="number-field"
                onBlur={this.onBlur}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onKeyDown={this.onKeyDown}
                type="text"
                value={this.state.value}
            />
        );
    }
}

export function getCurrentCaretPosition(el: HTMLInputElement) {
    return Math.max(el.selectionStart || 0, el.selectionEnd || 0);
}

export function isNil(val: unknown) {
    return val === null || val === undefined;
}

export function numberAsString(num: number, maxLength: number) {
    return num.toString().padStart(maxLength, '0');
}
