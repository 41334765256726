/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import React from 'react';

import CCEditorActions from '../../cc-editor-actions';

type Props = {
    isActive: boolean,
    gap: CCEditorRegion$Gap,
}

export default function CueGap(props: Props) {
    const cn = ClassNames({
        'cue-gap': true,
        active: props.isActive,
    });
    // TODO: disable add button if it's not possible to edit captions (mastering)
    return (
        <div className="cue-gap-wrapper">
            <button className={cn} onClick={/*istanbul ignore next*/() => CCEditorActions.addCue(props.gap)}>
                <i className="fas fa-map-marker-plus fa-rotate-270"/>
            </button>
        </div>
    );
}
