/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel, Modal} from 'react-bootstrap';

import DocumentTitle from '../../common/document-title';
import {DownloadActions} from '../../common/download/download-actions';
import {FormItem, FormRow, FormSection} from '../../common/form/form';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import {UtilitiesActions, UtilitiesConstants} from '../utilities-actions';
import UtilitiesStore, {UtilitiesValidations} from '../utilities-store';

let options = Object.keys(UtilitiesConstants.ELASTIC_REINDEX.TYPE).map((option) => (
    UtilitiesConstants.ELASTIC_REINDEX.TYPE[option]
));

class Browse extends Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            anonymousAccount: UtilitiesStore.getState().get('anonymousAccount')
        };
    }

    static getStores() {
        return [PreloaderStore, UtilitiesStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            dateRange: Immutable.Map({
                endDate: '',
                startDate: ''
            }),
            showTitleIdModal: false,
            title: Immutable.Map({
                titleId: undefined,
            }),
            usageGroup: 'OTHER'
        }, this.constructor.calculateState());

        this.handleDownloadForensicUsage = this.handleDownloadForensicUsage.bind(this);
        this.handleElasticSearchTitleId = this.handleElasticSearchTitleId.bind(this);
        this.handleElasticSearchVideoSearch = this.handleElasticSearchVideoSearch.bind(this);
        this.handleReportError = this.handleReportError.bind(this);
        this.handleShowTitleIdModal = this.handleShowTitleIdModal.bind(this);
        this.handleTriggerCloudflare = this.handleTriggerCloudflare.bind(this);
        this.handleUsageGroupChange = this.handleUsageGroupChange.bind(this);
        this.onHideTitleIdModal = this.onHideTitleIdModal.bind(this);
        this.setDate = this.setDate.bind(this);
        this.setTitleId = this.setTitleId.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.dateRange !== this.state.dateRange ||
            nextState.title !== this.state.title ||
            nextState.usageGroup !== this.state.usageGroup) {
            return true;
        }
        return false;
    }

    handleDownloadForensicUsage() {
        let query = {
            'start-date': Moment(this.state.dateRange.get('startDate')).format('YYYY-MM-DDTHH:00:SS'),
            'end-date': Moment(this.state.dateRange.get('endDate')).format('YYYY-MM-DDTHH:00:SS'),
            'usage-group': this.state.usageGroup
        };

        DownloadActions.startDownloadExecution('asset/video/forensic/usage', query);
    }

    handleElasticSearch(type) {
        UtilitiesActions.syncElasticSearch(type);
        return;
    }

    handleElasticSearchVideoSearch() {
        UtilitiesActions.syncElasticSearch('videoSearch');
    }

    handleElasticSearchTitleId() {
        UtilitiesActions.syncElasticSearchByTitleId(this.state.title.get('titleId'));
        this.onHideTitleIdModal();
        return;
    }

    handleReportError() {
        // Create a sample error report
        UtilitiesActions.reportError();
    }

    handleShowTitleIdModal() {
        this.setState(() => {
            return {
                showTitleIdModal: true
            };
        });
        return;
    }

    handleTriggerCloudflare() {
        // Trigger cloudlfare
        UtilitiesActions.triggerCloudflare();
    }

    handleHboMaxIngest() {
        UtilitiesActions.hboMaxImageIngest();
    }

    handleRefreshImageURL() {
        UtilitiesActions.syncRefreshImages();
    }

    handlePublishingListURL() {
        UtilitiesActions.syncRefreshPublishingList();
    }

    handleSaveAnonymousAccounts(anonymousAccount) {
        UtilitiesActions.saveAnonymousAccounts(anonymousAccount);
    }

    handleUsageGroupChange(event) {
        this.setState({
            usageGroup: event.target.value
        });
    }

    onHideTitleIdModal() {
        this.setState(() => {
            return {
                title: Immutable.Map({titleId: undefined}),
                showTitleIdModal: false
            };
        });
    }

    setDate(attr, value) {
        this.setState((prevState)=>{
            prevState.dateRange = prevState.dateRange.set(attr, value);
        });
    }

    setTitleId(attr, value) {
        this.setState((prevState)=>{
            prevState.title = prevState.title.set(attr, value);
        });
    }

    render() {
        let disableButton = !this.state.dateRange.get('endDate') || !this.state.dateRange.get('startDate');
        let row = options.map((item) => {
            return (<div>
                <Button bsStyle="primary" className="btn-lg btn-block" onClick={this.handleElasticSearch.bind(this, item.id)}>
                    <i className="far fa-newspaper"/>&nbsp;{this.context.intl.messages[`utilities.elastic-reindex.${item.id}.sync`]}
                </Button>
            </div>);
        });

        const elasticReindexModal = <Modal show={this.state.showTitleIdModal} onHide={this.onHideTitleIdModal}>
            <Modal.Header className="bg-gray">
                <Modal.Title className="text-center" >{this.context.intl.messages['utilities.elastic-reindex.title-id.modal.title']}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {this.context.intl.messages['utilities.elastic-reindex.title-id.modal.body']}
                </p><hr />
                <FormItem
                    attr="titleId"
                    label="Title ID"
                    model={this.state.title}
                    setter={this.setTitleId}
                    type="number"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button className="pull-left Ml(5)" onClick={this.onHideTitleIdModal}>Cancel</Button>
                <Button bsStyle="primary" disabled={!this.state.title.get('titleId')} onClick={this.handleElasticSearchTitleId}>
                    {this.context.intl.messages['utilities.elastic-reindex.title-id.modal.confirm']}
                </Button>
            </Modal.Footer>
        </Modal>;

        return (
            <div>
                <DocumentTitle
                    message="document-titles.utilities"
                >
                    <div>
                        <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                            <section className="content-header padding-x-20 padding-bottom-20">
                                <h1>
                                    <i className="fas fa-cog"/>&nbsp;{this.context.intl.messages['utilities.title']}
                                </h1>
                            </section>
                            <section className="padding-x-20 padding-bottom-20">
                                <FormSection>
                                    <h3>
                                        <i className="fas fa-database"></i>&nbsp;{this.context.intl.messages['utilities.elastic-reindex.title']}
                                    </h3>
                                    <div className="box">
                                        <div className="box-body padding-x-20 padding-y-20">
                                            <FormRow>
                                                {row}
                                            </FormRow>
                                            <br />
                                            <FormRow>
                                                <Button bsStyle="primary" className="btn-lg btn-block" onClick={this.handleShowTitleIdModal}>
                                                    <i className="far fa-newspaper"/>&nbsp;{this.context.intl.messages['utilities.elastic-reindex.title-id.sync']}
                                                </Button>
                                                <Button bsStyle="primary" className="btn-lg btn-block" onClick={this.handleElasticSearchVideoSearch}>
                                                    <i className="far fa-newspaper"/>&nbsp;{this.context.intl.messages['utilities.elastic-reindex.video-search.sync']}
                                                </Button>
                                                <div />
                                                <div />
                                            </FormRow>
                                        </div>
                                    </div>
                                </FormSection>
                                <hr />
                                <FormSection>
                                    <h3>
                                        <i className="fas fa-sync-alt"></i>&nbsp;{this.context.intl.messages['utilities.refresh-url-cache.title']}
                                    </h3>
                                    <div className="box">
                                        <div className="box-body padding-x-10 padding-y-20">
                                            <div className="col-md-6">
                                                <FormRow>
                                                    <Button bsStyle="primary" className="btn-lg btn-block" onClick={this.handleRefreshImageURL.bind(this)}>
                                                        <i className="far fa-file-image"/>&nbsp;{this.context.intl.messages['utilities.refresh-image.title']}
                                                    </Button>
                                                    <Button bsStyle="primary" className="btn-lg btn-block" onClick={this.handlePublishingListURL.bind(this)}>
                                                        <i className="far fa-list-alt"/>&nbsp;{this.context.intl.messages['utilities.refresh-publishing-list.title']}
                                                    </Button>
                                                </FormRow>
                                            </div>
                                        </div>
                                    </div>
                                </FormSection>
                                <hr />
                                <FormSection>
                                    <h3>
                                        <i className="fas fa-download"></i>&nbsp;{this.context.intl.messages['utilities.forensic-video.title']}
                                    </h3>
                                    <div className="box">
                                        <div className="box-body padding-x-10 padding-y-20">
                                            <FormRow>
                                                <div className="box-body date-picker-filter">
                                                    <div className="col-md-2 padding-x-10 padding-y-10">
                                                        <FormItem
                                                            addonBefore={<i className="far fa-calendar-alt"></i>}
                                                            attr="startDate"
                                                            datepicker={{popoverTargetOffset: '10px -36px'}}
                                                            label={this.context.intl.messages['utilities.forensic-video.from']}
                                                            model={this.state.dateRange}
                                                            setter={this.setDate}
                                                            type="date"
                                                            validations={UtilitiesValidations.startDate.validations}
                                                        />
                                                    </div>
                                                    <div className="col-md-2 padding-x-10 padding-y-10">
                                                        <FormItem
                                                            addonBefore={<i className="far fa-calendar-alt"></i>}
                                                            attr="endDate"
                                                            datepicker={{popoverTargetOffset: '10px -36px'}}
                                                            label={this.context.intl.messages['utilities.forensic-video.to']}
                                                            model={this.state.dateRange}
                                                            setter={this.setDate}
                                                            type="date"
                                                            validations={UtilitiesValidations.endDate.validations}
                                                        />
                                                    </div>
                                                    <div className="col-md-3 padding-x-10 padding-y-10">
                                                        <ControlLabel>{this.context.intl.messages['utilities.forensic-video.usage-group.title']}</ControlLabel>
                                                        <select
                                                            className="form-control pull-right media-asset-type-select"
                                                            onChange={this.handleUsageGroupChange}
                                                        >
                                                            <option value={UtilitiesConstants.USAGE_GROUP.OTHER.name}>OTHER</option>
                                                            <option value={UtilitiesConstants.USAGE_GROUP.FYC.name}>FYC</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </FormRow>
                                            <FormRow>
                                                <div className="col-md-6 padding-x-20">
                                                    <Button
                                                        className="btn btn-lg btn-block btn-primary"
                                                        onClick={this.handleDownloadForensicUsage}
                                                        disabled={disableButton}
                                                    >
                                                        <i className="fas fa-download"/>&nbsp;{this.context.intl.messages['utilities.forensic-video.download-button']}
                                                    </Button>
                                                </div>
                                            </FormRow>
                                        </div>
                                    </div>
                                </FormSection>
                                <hr />
                                <FormSection>
                                    <h3>
                                        <i className="fas fa-user"></i>&nbsp;{this.context.intl.messages['utilities.generate-anonymous-accounts.title']}
                                    </h3>
                                    <div className="box">
                                        <div className="box-body padding-x-10 padding-y-20">
                                            <FormRow>
                                                <div className="box-body">
                                                    <div className="col-md-4 padding-x-10 padding-y-10">
                                                        <FormItem attr="defaultDomainName"
                                                            label={this.context.intl.messages['utilities.create-anonymous.default-domain-name']}
                                                            model={this.state.anonymousAccount}
                                                            setter={UtilitiesActions.updateAnonymousAccounts}
                                                            type="text"
                                                        />
                                                    </div>
                                                    <div className="col-md-2 padding-x-10 padding-y-10">
                                                        <FormItem attr="numberOfAccounts"
                                                            label={this.context.intl.messages['utilities.create-anonymous.number-accounts']}
                                                            model={this.state.anonymousAccount}
                                                            setter={UtilitiesActions.updateAnonymousAccounts}
                                                            type="number"
                                                        />
                                                    </div>
                                                    <div className="col-md-2 padding-x-10 padding-y-10">
                                                        <FormItem attr="permissionPackageId"
                                                            label={this.context.intl.messages['utilities.create-anonymous.number-packages']}
                                                            model={this.state.anonymousAccount}
                                                            setter={UtilitiesActions.updateAnonymousAccounts}
                                                            type="number"
                                                        />
                                                    </div>
                                                </div>
                                            </FormRow>
                                            <FormRow>
                                                <div className="box-body date-picker-filter">
                                                    <div className="col-md-2 padding-x-10 padding-y-10">
                                                        <FormItem
                                                            attr="validFromDate"
                                                            label={this.context.intl.messages['utilities.create-anonymous.valid-from']}
                                                            model={this.state.anonymousAccount}
                                                            setter={UtilitiesActions.updateAnonymousAccounts}
                                                            type="date"
                                                        />
                                                    </div>
                                                    <div className="col-md-2 padding-x-10 padding-y-10">
                                                        <FormItem
                                                            attr="validToDate"
                                                            label={this.context.intl.messages['utilities.create-anonymous.valid-to']}
                                                            model={this.state.anonymousAccount}
                                                            setter={UtilitiesActions.updateAnonymousAccounts}
                                                            type="date"
                                                        />
                                                    </div>
                                                    <div className="col-md-2 padding-x-10 padding-y-10">
                                                        <FormItem
                                                            attr="active"
                                                            label={this.context.intl.messages['utilities.create-anonymous.active']}
                                                            model={this.state.anonymousAccount}
                                                            setter={UtilitiesActions.updateAnonymousAccounts}
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                    <div className="col-md-2 padding-x-10 padding-y-10">
                                                        <FormItem
                                                            attr="createAdminAccount"
                                                            label={this.context.intl.messages['utilities.create-anonymous.create-admin-account']}
                                                            model={this.state.anonymousAccount}
                                                            setter={UtilitiesActions.updateAnonymousAccounts}
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                </div>
                                            </FormRow>
                                            <FormRow>
                                                <div className="col-md-6 padding-x-20">
                                                    <Button bsStyle="success" className="btn-lg btn-block" onClick={this.handleSaveAnonymousAccounts.bind(this, this.state.anonymousAccount)}>
                                                        <i className="fas fa-save"/>&nbsp;{this.context.intl.messages['utilities.generate-anonymous-accounts.save']}
                                                    </Button>
                                                </div>
                                            </FormRow>
                                        </div>
                                    </div>
                                </FormSection>
                                <hr />
                                <FormSection>
                                    <h3>
                                        <i className="fas fa-upload"></i>&nbsp;{this.context.intl.messages['utilities.hbo-max-image-ingest.title']}
                                    </h3>
                                    <div className="box">
                                        <div className="box-body padding-x-10 padding-y-20">
                                            <div className="col-md-6">
                                                <p>{this.context.intl.messages['utilities.hbo-max-image-ingest.description']}</p>
                                                <FormRow>
                                                    <Button bsStyle="primary" className="btn-lg btn-block" onClick={this.handleHboMaxIngest}>
                                                        <i className="far fa-upload"/>&nbsp;{this.context.intl.messages['utilities.hbo-max-image-ingest.button']}
                                                    </Button>
                                                </FormRow>
                                            </div>
                                        </div>
                                    </div>
                                </FormSection>

                                <hr />

                                <FormSection>
                                    <h3>
                                        <i className="fas fa-exclamation-circle"></i>&nbsp;{this.context.intl.messages['utilities.error-reporting']}
                                    </h3>
                                    <div className="box">
                                        <div className="box-body padding-x-10 padding-y-20">
                                            <div className="col-md-6">
                                                <p>{this.context.intl.messages['utilities.error-reporting.description']}</p>
                                                <FormRow>
                                                    <Button bsStyle="primary" className="btn-lg btn-block" onClick={this.handleReportError}>
                                                        <i className="far fa-exclamation-circle"/>&nbsp;{this.context.intl.messages['utilities.error-reporting.create-error']}
                                                    </Button>
                                                    <Button bsStyle="primary" className="btn-lg btn-block" onClick={this.handleTriggerCloudflare}>
                                                        <i className="far fa-cloud"/>&nbsp;{this.context.intl.messages['utilities.error-reporting.create-cloudflare-error']}
                                                    </Button>
                                                </FormRow>
                                            </div>
                                        </div>
                                    </div>
                                </FormSection>
                            </section>
                        </Preloader>
                    </div>
                </DocumentTitle>
                {elasticReindexModal}
            </div>
        );
    }
}

export {Browse as Browse_BASE};
export default Container.create(Browse);
