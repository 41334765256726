/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Select from 'react-select';

import {FormItem} from '../../common/form/form';
import Panel from '../../common/panel/panel';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {TITLE_FIELDS, TITLE_FIELDS_STATUS} from '../../dashboard/fields';
import {StatusFields} from '../../dashboard/filter-options';
import {LayoutActions} from '../../layout/layout-actions';
import {TitleActions, TitleConstants} from '../title-actions';
import TitleStore from '../title-store';

import {GetAttr} from '~/src/common/utils/utils';

const noop = () => void 0;

const ActiveOptions = WithStoreOnRoute(class ActiveOptions extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object,
            onChange: PropTypes.func,
            selected: PropTypes.number
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            location: undefined,
            onChange: noop,
            selected: undefined
        };
    }

    constructor(props) {
        super(props);

        this.setActiveFilter = this.setActiveFilter.bind(this);
    }

    setActiveFilter(e) {
        const filterAttr = 'active';
        let value = 'ACTIVE';

        if (e) {
            value = e.id;
        }

        if (this.props.location) {
            this.setRouteState(filterAttr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        }

        this.props.onChange(filterAttr, value);
    }

    render() {
        let options = [];
        Object.keys(TitleConstants.FILTERS.ACTIVE_OPTIONS).map((option) => (
            options.push(TitleConstants.FILTERS.ACTIVE_OPTIONS[option])
        ));

        let selected = options[0];
        if (this.props.selected) {
            selected = options.find(op => op.id === this.props.selected);
        }

        return (
            <Panel title={this.context.intl.messages['filter-options.status']} classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            classNamePrefix="dropdown"
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            isClearable={true}
                            isMulti={false}
                            name="active"
                            onChange={this.setActiveFilter}
                            options={options}
                            placeholder={this.context.intl.messages['filter-options.select.placeholder']}
                            value={selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

const CategoryOptions = WithStoreOnRoute(class CategoryOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object,
            onChange: PropTypes.func,
            selected: PropTypes.number
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            location: undefined,
            onChange: noop,
            selected: undefined
        };
    }

    constructor(props) {
        super(props);

        this.setCategoryFilter = this.setCategoryFilter.bind(this);
    }

    setCategoryFilter(selected) {
        if (this.props.location) {
            let selectedId = undefined;
            if (selected) {
                selectedId = selected.id;
            }
            this.setRouteState('category-id', selectedId)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        }

        this.props.onChange('category', selected || undefined);
    }

    render() {
        let options = Object.keys(TitleConstants.TITLE_TYPES).map(
            option => TitleConstants.TITLE_TYPES[option]
        );

        let selected = options.find((item) => {
            return item.id === this.props.selected;
        });

        // This is hacky, but if we pass undefined to Select value sometimes the internal state won't get cleared
        if (selected === undefined) {
            selected = null;
        }

        return (
            <Panel title={this.context.intl.messages['filter-options.title-type']} classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            classNamePrefix="dropdown"
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            isClearable={true}
                            isMulti={false}
                            name="category"
                            onChange={this.setCategoryFilter}
                            options={options}
                            placeholder={this.context.intl.messages['filter-options.select.placeholder']}
                            value={selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

const CreateDateOptions = WithStoreOnRoute(class CreateDateOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object,
            onChange: PropTypes.func
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            location: undefined,
            onChange: noop
        };
    }

    constructor(props) {
        super(props);

        this.setFormatDate = this.setFormatDate.bind(this);
    }

    setFormatDate(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        }

        this.props.onChange(attr, value);
    }

    render() {
        const model = this.getRouteState();

        return (
            <Panel title={this.context.intl.messages['filter-options.created-date']} classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body date-picker-filter">
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.from']}
                        attr="start-date-created"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        setter={this.setFormatDate}
                        type="date"
                    />
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.to']}
                        attr="end-date-created"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        setter={this.setFormatDate}
                        type="date"
                    />
                </div>
            </Panel>
        );
    }
});

const MpmNumberOptions = WithStoreOnRoute(class MpmNumberOptions extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object,
            onChange: PropTypes.func
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            location: undefined,
            onChange: noop
        };
    }

    constructor(props) {
        super(props);

        this.setNumber = this.setNumber.bind(this);
    }

    setNumber(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        }

        this.props.onChange(attr, value);
    }

    render() {
        const model = this.getRouteState();

        return (
            <Panel title={this.context.intl.messages['filter-options.mpm-number']} classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body">
                    <FormItem
                        attr="mpm-number"
                        label={this.context.intl.messages['filter-options.mpm-number']}
                        model={model}
                        setter={this.setNumber}
                        type="text"
                    />
                </div>
            </Panel>
        );
    }
});

const ReferenceIdOptions = WithStoreOnRoute(class ReferenceIdOptions extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object,
            onChange: PropTypes.func,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            location: undefined,
            onChange: noop
        };
    }

    constructor(props) {
        super(props);

        this.setReferenceFilter = this.setReferenceFilter.bind(this);
    }

    setReferenceFilter(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        }

        this.props.onChange(attr, value);
    }

    render() {
        const model = this.getRouteState();

        return (
            <Panel title={this.context.intl.messages['filter-options.reference-id']} classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body">
                    <FormItem
                        attr="reference-id"
                        label={this.context.intl.messages['filter-options.reference-id']}
                        model={model}
                        setter={this.setReferenceFilter}
                        type="text"
                    />
                </div>
            </Panel>
        );
    }
});

const ReleaseDateOptions = WithStoreOnRoute(class ReleaseDateOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object,
            onChange: PropTypes.func
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            location: undefined,
            onChange: noop
        };
    }

    constructor(props) {
        super(props);

        this.setFormatDate = this.setFormatDate.bind(this);
    }

    setFormatDate(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        }

        this.props.onChange(attr, value);
    }

    render() {
        const model = this.getRouteState();

        return (
            <Panel title={this.context.intl.messages['filter-options.release-date']} classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body date-picker-filter">
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.from']}
                        attr="start-release-date"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        setter={this.setFormatDate}
                        type="date"
                    />
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.to']}
                        attr="end-release-date"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        setter={this.setFormatDate}
                        type="date"
                    />
                </div>
            </Panel>
        );
    }
});

const TitleOptions = WithStoreOnRoute(class TitleOptions extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object,
            onChange: PropTypes.func,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            location: undefined,
            onChange: noop
        };
    }

    constructor(props) {
        super(props);

        this.setTitleFilter = this.setTitleFilter.bind(this);
    }

    setTitleFilter(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        }

        this.props.onChange(attr, value);
    }

    render() {
        const model = this.getRouteState();

        return (
            <Panel title={this.context.intl.messages['filter-options.title']} classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body">
                    <FormItem
                        attr="title"
                        label={this.context.intl.messages['filter-options.title']}
                        model={model}
                        setter={this.setTitleFilter}
                        type="text"
                    />
                </div>
            </Panel>
        );
    }
});

class FilterOptions extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object,
            showActive: PropTypes.bool,
            showCategory: PropTypes.bool,
            showCreateDate: PropTypes.bool,
            showMpmNumber: PropTypes.bool,
            showReferenceId: PropTypes.bool,
            showReleaseDate: PropTypes.bool,
            showStatusFields: PropTypes.bool,
            showTitle: PropTypes.bool,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            location: undefined,
            showActive: true,
            showCategory: true,
            showCreateDate: true,
            showMpmNumber: true,
            showReferenceId: true,
            showReleaseDate: true,
            showStatusFields: true,
            showTitle: true,
        };
    }

    constructor(props) {
        super(props);

        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
        this.arrayFromQuery = this.arrayFromQuery.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.location !== this.props.location) {
            return true;
        }

        return false;
    }

    arrayFromQuery(param, map, findBy = false) {
        let arr = [];
        let p = this.props.location.query[param];

        let getValue = (m, v) => {
            if (!findBy) {
                return m[v];
            }

            let r;
            Object.keys(m).some(k => {
                // Always convert to string, since "v" comes from the query string.
                if (m[k][findBy].toString() === v) {
                    r = m[k];
                }
            });

            return r;
        };

        if (p) {
            if (!Array.isArray(p)) {
                arr.push(getValue(map, p));
            } else {
                arr = p.map(v => getValue(map, v));
            }
        }
        return arr;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
    }

    onChange(attr, value) {
        TitleActions.setFilter(attr, value);
        TitleActions.setFilter('operator', 'AND');
        TitleActions.get(
            TitleStore.getState().get('filters'),
            0,
            20
        );
    }

    render() {
        const activeValue = this.props.location.query.active;
        const category= parseInt(this.props.location.query['category-id'] || -1);
        let activeOptions;
        let mpmNumberOptions;
        let referenceIdOptions;
        let categoryOptions;
        let titleOptions;
        let createDateOptions;
        let releaseDateOptions;
        let statusFieldsOptions;

        if (this.props.showActive) {
            activeOptions = <ActiveOptions
                location={this.props.location}
                onChange={this.onChange}
                selected={activeValue}
            />;
        }

        if (this.props.showMpmNumber) {
            mpmNumberOptions = <MpmNumberOptions
                location={this.props.location}
                onChange={this.onChange}
            />;
        }

        if (this.props.showReferenceId) {
            referenceIdOptions = <ReferenceIdOptions
                location={this.props.location}
                onChange={this.onChange}
            />;
        }

        if (this.props.showCategory) {
            categoryOptions = <CategoryOptions
                location={this.props.location}
                onChange={this.onChange}
                selected={category}
            />;
        }

        if (this.props.showTitle) {
            titleOptions = <TitleOptions
                location={this.props.location}
                onChange={this.onChange}
            />;
        }

        if (this.props.showCreateDate) {
            createDateOptions = <CreateDateOptions
                location={this.props.location}
                onChange={this.onChange}
            />;
        }

        if (this.props.showReleaseDate) {
            releaseDateOptions = <ReleaseDateOptions
                location={this.props.location}
                onChange={this.onChange}
            />;
        }

        if (this.props.showStatusFields) {
            const fields = this.arrayFromQuery('field-name-type', TITLE_FIELDS, 'id');
            const values = this.arrayFromQuery('field-status-type', TITLE_FIELDS_STATUS, 'id');

            statusFieldsOptions = <StatusFields
                fields={fields}
                location={this.props.location}
                values={values}
                onChange={() => TitleActions.get(this.getRouteState())}
                all={this.props.location.query['status-operator'] === 'AND'}
                defaultExpanded={true}
            />;
        }

        return (
            <div>
                <aside className="control-sidebar B(0) Ovx(h) Ovy(a)">
                    <div className="tab-content">
                        <p>
                            <button className="btn btn-block bg-navy" onClick={this.handleToggleFiltersPanel}>
                                <i className="fas fa-chevron-right pull-right"></i>&nbsp;{this.context.intl.messages['filter-options.close-options.button']}
                            </button>
                        </p>
                        {activeOptions}
                        {mpmNumberOptions}
                        {referenceIdOptions}
                        {categoryOptions}
                        {titleOptions}
                        {createDateOptions}
                        {releaseDateOptions}
                        {statusFieldsOptions}
                    </div>
                </aside>
                <div style={{position: 'fixed', height: 'auto'}} className="control-sidebar-bg"></div>
            </div>
        );
    }
}

export default WithStoreOnRoute(FilterOptions);

export {
    ActiveOptions,
    CategoryOptions,
    CreateDateOptions,
    MpmNumberOptions,
    ReferenceIdOptions,
    ReleaseDateOptions,
    TitleOptions,
};
