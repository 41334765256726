/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
/* global tinymce*/

import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import {findDOMNode} from 'react-dom';

import './tinymce.less';
import '../../publishing-list/create/list-items/list-items.less';

let TinyMCE = createReactClass({
    displayName: 'TinyMCE',

    propTypes: {
        allowLink: PropTypes.bool,
        className: PropTypes.string,
        content: PropTypes.string,
        contentStyle: PropTypes.string,
        disabled: PropTypes.bool,
        forcedRootBlock: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        formats: PropTypes.string,
        height: PropTypes.number,
        helpPagesToolbar: PropTypes.bool,
        id: PropTypes.string.isRequired,
        imageUploadHandler: PropTypes.func,
        /**
         * For docs on 'how to' write formats on TinyMCE
         * @see https://www.tinymce.com/docs/configure/content-formatting/#block_formats
         */
        onChange: PropTypes.func.isRequired,
        pasteAsText: PropTypes.bool,
        placeholder: PropTypes.string,
        resize: PropTypes.oneOf([true, false, 'both']),
        wordCount: PropTypes.bool
    },

    getDefaultProps() {
        return {
            allowLink: false,
            className: '',
            content: '',
            contentStyle: '',
            disabled: false,
            forcedRootBlock: 'p',
            formats: '',
            height: 160,
            helpPagesToolbar: false,
            imageUploadHandler: /*istanbul ignore next*/() => void 0,
            pasteAsText: true,
            placeholder: '',
            resize: false,
            wordCount: false,
        };
    },

    getInitialState() {
        return {
            editorReady: false
        };
    },

    componentWillMount() {
        this.id = this.props.id;
    },

    componentDidMount() {
        let blocks = '|';
        if (this.props.formats) {
            blocks = '| blocks |';
        }

        let plugins = ['charmap', 'code'];
        let link = '';
        if (this.props.allowLink) {
            link = 'link';
            plugins.push('link');
        }

        let toolBar = `undo redo ${blocks} bold italic | charmap ${link} pastetext code`;

        if (this.props.helpPagesToolbar) {
            toolBar = 'undo redo | searchreplace | blocks | bold italic removeformat | alignleft aligncenter alignright | bullist numlist | link | image | anchor | code pastetext';
            plugins.push('searchreplace', 'lists', 'link', 'image', 'anchor');
        }

        const config = {
            block_formats: this.props.formats,
            branding: false,
            entity_encoding: 'raw', // Disables tinyMCE automatically escaping international symbols to html entities. Fixes hasChanged() bug with title.synopsis etc
            height: this.props.height, // tinyMCE v5 height includes toolbar/statusbar
            images_reuse_filename: true,
            images_upload_handler: this.props.imageUploadHandler,
            inline: false,
            invalid_elements: 'applet,area,audio,body,canvas,embed,head,iframe,img,map,meta,noembed,noscript,object,param,picture,script,source,style,track,video',
            link_target_list: false,
            menubar: false,
            paste_as_text: this.props.pasteAsText,
            placeholder: this.props.placeholder,
            plugins: plugins,
            readonly: !!this.props.disabled,
            resize: !!this.props.resize,
            statusbar: !!this.props.resize,
            toolbar: toolBar,
            valid_elements: '*[*]'
        };
        if (this.props.contentStyle) {
            config.content_style = this.props.contentStyle;
        }
        if (this.props.forcedRootBlock !== undefined) {
            config.forced_root_block = this.props.forcedRootBlock;
        }
        this._init(config);
    },

    shouldComponentUpdate(nextProps, nextState) {
        if (this._editor) {
            if (this.props.disabled !== nextProps.disabled) {
                let mode = 'design';
                if (nextProps.disabled) {
                    mode = 'readonly';
                }

                this._editor.mode.set(mode);
            }
            try {
                if (this.props.content !== nextProps.content ||
                    this._editor.getContent() !== nextProps.content ||
                    this.state.editorReady !== nextState.editorReady) {

                    // Don't send null to tinyMCE or it will break.
                    // but only doit if content is distint
                    if (this._editor.getContent() !== nextProps.content) {
                        this._editor.setContent(nextProps.content || '');
                    }
                    return true;
                }
            } catch (err) {
                return false;
            }
        }
        return false;
    },

    componentWillUnmount() {
        this._remove();
    },

    /* istanbul ignore next */
    _init(config) {
        if (this._isInit) {
            this._remove();
            this._editor = undefined;
        }
        /* istanbul ignore next */
        // hide the textarea that is me so that no one sees it
        // note: findDOMNode is deprecated and breaks enzyme snapshot tests
        findDOMNode(this).style.hidden = 'hidden';

        config.selector = '#' + this.id;
        if (this.props.wordCount) {
            config.plugins = 'wordcount';
        }
        config.setup = (editor) => {
            this._editor = editor;
            editor.on('keyup', () => {
                // native DOM events don't have access to the editor so we pass it here
                this.props.onChange({target: editor});
            });
            editor.on('change', (e) => {
                this.props.onChange(e, editor);
            });

            editor.on('init', () => {
                this.setState({
                    editorReady: true
                });
            });
        };
        tinymce.init(config);
        findDOMNode(this).style.hidden = '';
        this._isInit = true;
    },

    /* istanbul ignore next */
    _remove() {
        tinymce.EditorManager.execCommand('mceRemoveEditor', true, this.id);
        this._isInit = false;
        this._editor = undefined;
    },

    render() {
        let content = '';
        if (this.props.content) {
            content = this.props.content;
        }

        return (
            <textarea
                id={this.id}
                className={this.props.className}
                defaultValue={content}
            />
        );
    }

});

let LocalizedTinyMCE = createReactClass({

    propTypes: {
        content: PropTypes.node,
        disabled: PropTypes.bool,
        enableLink: PropTypes.bool,
        handleShowDescriptionModal: PropTypes.func,
        helpElement: PropTypes.element,
        id: PropTypes.string.isRequired,
        localized: PropTypes.bool,
        localizedContent: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired
    },

    contextTypes: {
        intl: PropTypes.object.isRequired
    },

    getDefaultProps() {
        return {
            content: undefined,
            disabled: false,
            enableLink: false,
            handleShowDescriptionModal: /*istanbul ignore next*/() => void 0,
            helpElement: undefined,
            localized: false,
            localizedContent: '',
        };
    },

    render() {
        // if language === english work as before
        // else show english value as hint and do the blue stuff

        let style='body {background-color: #f0faff;}';
        let item = <FormGroup>
            <ControlLabel>{this.props.title} {this.props.helpElement}</ControlLabel>
            <TinyMCE
                {...this.props}
            />
        </FormGroup>;

        let viewFullDescriptionLink;
        if (this.props.enableLink) {
            viewFullDescriptionLink = <a className="clickable" onClick={this.props.handleShowDescriptionModal}>
                {this.context.intl.messages['common.handleClick.description']}
            </a>;
        }

        let content = ' - ';
        if (this.props.content) {
            content = this.props.content;
        }

        if (this.props.localized) {
            item = (
                <div className="active-field">
                    <FormGroup>
                        <ControlLabel>{this.props.title} {this.props.helpElement}</ControlLabel>
                        <TinyMCE
                            content={this.props.localizedContent || ''}
                            disabled={this.props.disabled}
                            id={this.props.id}
                            onChange={this.props.onChange}
                            contentStyle={style}
                        />
                    </FormGroup>
                    <div className="hint">
                        <small>
                            <strong>English: </strong>
                        </small>
                        <small className="mce-locale-description active-field" dangerouslySetInnerHTML={{__html: content}}></small>
                        <small>{viewFullDescriptionLink}</small>
                    </div>
                </div>
            );
        }
        return item;
    }
});

export default TinyMCE;
export {
    LocalizedTinyMCE
};
