/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import CueGap from './cue-gap';
import CueItem from './cue-item';
import {ActiveRegionsAsMap$, IsMasteringVttFile$, ListOfRegions$, SelectedCues$} from '../../bl';
import {DEFAULT_FRAME_RATE} from '../../constants';

import SMPTE from '~/src/common/utils/smpte';
import WithRxSubscription from '~/src/decorators/with-rx-subscriptions';

type Props = {
    activeRegions: DeepImmutable<Dictionary<CCEditorRegion>>,
    frameRate: number | undefined,
    isMasteringVttFile: boolean,
    regions: ReadonlyArray<CCEditorRegion>,
    selectedCues: ReadonlyArray<WBTVDCue>
}

export class CuesList extends React.PureComponent<Props> {
    /*istanbul ignore next*/
    componentDidUpdate(props: Props) {
        if (props.frameRate !== this.props.frameRate) {
            this.smpte = SMPTE.create({frameRate: this.props.frameRate || DEFAULT_FRAME_RATE});
        }
    }

    smpte = SMPTE.create({frameRate: DEFAULT_FRAME_RATE});

    render() {
        const selected = this.props.selectedCues.reduce((memo, cue) => {
            memo[cue.id] = cue;
            return memo;
        }, {} as Dictionary<WBTVDCue>);
        return (
            <div>
                {this.props.regions.map(region => {
                    if (!region) {
                        return;
                    }
                    const regionId = region.id;
                    const isActive = !!this.props.activeRegions[regionId];
                    let cn = '';
                    if (isActive) {
                        cn = 'active';
                    }
                    let mode: 'view' | 'edit' = 'view';
                    if (selected[regionId] && !this.props.isMasteringVttFile) {
                        mode = 'edit';
                    }
                    if (region.type === 'cue') {
                        return (
                            <CueItem
                                className={cn}
                                converter={this.smpte}
                                cue={selected[regionId] || region}
                                key={regionId}
                                mode={mode}
                            />
                        );
                    }
                    return <CueGap key={regionId} isActive={isActive} gap={region} />;
                })}
            </div>
        );
    }
}

export default WithRxSubscription(CuesList, {
    activeRegions: ActiveRegionsAsMap$,
    isMasteringVttFile: IsMasteringVttFile$,
    regions: ListOfRegions$,
    selectedCues: SelectedCues$
});
