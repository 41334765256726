/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {NameLinkRenderer, DateMomentRenderer} from '~/src/common/ag-grid/cell-renderers/index.js';
import AGGridWrapper from '~/src/common/ag-grid/index.js';
import PartnerStore from '~/src/partner/partner-store';

class Table extends Component {
    static get propTypes() {
        return {
            displayLinks: PropTypes.bool,
            partners: PropTypes.instanceOf(Immutable.List).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false,
        };
    }

    static getStores() {
        return [PartnerStore];
    }

    constructor(props) {
        super(props);

    }

    render() {
        const columnDefs = [
            {headerName: this.context.intl.messages['authorized-partners.partner-name'], field: 'name', sort: 'asc', cellRendererFramework: NameLinkRenderer, cellRendererParams: {displayLinks: this.props.displayLinks}, width: 350, suppressSizeToFit: true},
            {headerName: this.context.intl.messages['authorized-partners.client'], field: 'clientId', width: 350, suppressSizeToFit: true},
            {headerName: this.context.intl.messages['authorized-partners.description'], field: 'description', wrapText: true, cellStyle: {textWrap: 'wrap'}, flex: 1},
            {headerName: this.context.intl.messages['authorized-partners.date-added'], field: 'createdDate', cellRendererFramework: DateMomentRenderer, cellRendererParams: {format: this.context.intl.messages['date-format'], propName: 'createdDate'}, width: 115, suppressSizeToFit: true},
        ];

        const rowData = this.props.partners?.toJS();

        return <AGGridWrapper
            columnDefs={columnDefs}
            rowData={rowData}
            rowHeight={55}
        />;
    }
}

export default Table;

export {
    Table
};
