/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {Link} from 'react-router';

import RouteValidator from '../../common/custom-validator/route/route';
import DocumentTitle from '../../common/document-title';
import {History, SideNotes} from '../../common/side-notes/side-notes';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Preloader from '../../preloader';
import PreloaderStore from '../../preloader/preloader-store';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import SessionStore from '../../user/session/session-store';
import Summary from '../create/summary/summary';
import {TitleActions} from '../title-actions';
import TitleStore from '../title-store';

class DeletedTitle extends Component {

    static get propTypes() {
        return {
            children: PropTypes.array.isRequired,
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            route: RouteValidator
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }


    static get defaultProps() {
        return {
            route: undefined
        };
    }

    static getPermissions() {
        return {
            canRead: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.READ),
        };
    }

    static calculateState() {
        return {
            deletedTitle: TitleStore.getState().get('selectedTitle'),
            deletedTitles: TitleStore.getState().get('deletedTitles'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
        };
    }

    static getStores() {
        return [PreloaderStore, TitleStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showModal: false,
        }, this.constructor.calculateState());

        this.handleConfirmUndelete = this.handleConfirmUndelete.bind(this);
        this.handleToggleModal = this.handleToggleModal.bind(this);
        this.isActiveTab = this.isActiveTab.bind(this);
        this.toTab = this.toTab.bind(this);
    }

    componentDidMount() {
        TitleActions.findDeletedTitleById(this.props.params.id, this.state.deletedTitles.find(t => t.get('id') === parseInt(this.props.params.id)));
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.params.id !== nextProps.params.id ||
            this.props.route.mode !== nextProps.route.mode ||
            this.props.location.query.parentId !== nextProps.location.query.parentId ||
            nextState.deletedTitle !== this.state.deletedTitle;
    }

    handleCancel() {
        RouterActions.redirect('/titles/deleted');
    }

    handleConfirmUndelete() {
        this.handleToggleModal();
        TitleActions.undeleteTitle(this.props.params.id);
    }

    handleToggleModal() {
        this.setState((s) => ({showModal: !s.showModal}));
    }

    isActiveTab(tabName) {
        let isActive = false;
        let pathname = this.props.location.pathname;

        switch (true) {
        case !!(pathname.match(/\/history$/)):
            isActive = tabName === 'history';
            break;
        default:
            isActive = tabName === 'summary';
        }

        if (isActive) {
            return 'active';
        }

        return;
    }

    toTab(tabName, tabUrl, title, disabled, className) {
        if (tabUrl) {
            tabUrl = `/${tabUrl}`;
        } else {
            tabUrl = '';
        }
        if (disabled) {
            return (
                <li key={tabName} className={ClassNames(this.isActiveTab(tabName), className)}>
                    <a className="tab-disabled">
                        {this.context.intl.messages[title]}
                    </a>
                </li>
            );
        }
        return (
            <li key={tabName} className={ClassNames(this.isActiveTab(tabName), className)}>
                <Link to={`/titles/deleted/${this.props.params.id}${tabUrl}`}>
                    {this.context.intl.messages[title]}
                </Link>
            </li>
        );
    }

    render() {
        const actionsMenu = <ActionsMenu
            canSave={false}
            onCancel={this.handleCancel}
        >
            <Button
                bsSize="large"
                className="btn btn-success-outline Mr(3px) Mb(3px)"
                disabled={false}
                onClick={this.handleToggleModal}
            >
                <i className="fa-solid fa-trash-undo"/>&nbsp;{this.context.intl.messages['titles.deleted-title.unmark-title-for-deletion']}
            </Button>
        </ActionsMenu>;

        const entityName = this.state.deletedTitle.get('name');

        const baseRoute = `/titles/deleted/${this.props.params.id}`;
        const historyTabLabel = this.toTab('history', 'history', 'common.tab-title.history', false, 'pull-right');
        const tabMenu = (
            <ul className="nav nav-tabs">
                <li className={this.isActiveTab('summary')}>
                    <Link to={baseRoute}>{this.context.intl.messages['titles.create.summary-tab-title']}</Link>
                </li>
                {historyTabLabel}
            </ul>
        );

        const eventsAndNotes = (
            <SideNotes
                basePath={`/titles/deleted/${this.props.params.id}`}
                dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                disabled={true}
                notes={this.state.deletedTitle.get('history').toJS().slice(0, 4)}
                onAddNote={this.addNote}
                title={this.context.intl.messages['common.side-notes.title']}
            />
        );

        const modal = <Modal backdrop="static" show={this.state.showModal} onHide={this.handleToggleModal}>
            <Modal.Header className="alert-success" closeButton>
                <Modal.Title className="text-center" >{this.context.intl.messages['titles.deleted-title.unmark-title-for-deletion']}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {this.context.intl.messages['titles.deleted-title.modal.body']}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-default-outline pull-left" onClick={this.handleToggleModal}>
                    <i className="fas fa-ban" /> {this.context.intl.messages['common.cancel']}</Button>
                <Button className="btn btn-success-outline" onClick={this.handleConfirmUndelete}>
                    <i className="fa-regular fa-trash-undo" /> {this.context.intl.messages['titles.deleted-title.unmark-title-for-deletion']}
                </Button>
            </Modal.Footer>
        </Modal>;

        return (
            <DocumentTitle
                message={'document-titles.deleted-title'} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="row">
                            <div className="col-md-6">
                                <h1>
                                    <i className="far fa-newspaper"/>&nbsp;{this.context.intl.messages['titles.deleted-title']}&nbsp;
                                    <small className="d-block padding-y-10">{entityName}</small>
                                </h1>
                            </div>
                            {actionsMenu}
                        </div>
                    </StickyHeader>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="nav-tabs-custom">
                                        {tabMenu}
                                        <div className="tab-content">
                                            <div className="tab-pane padding-x-20 padding-bottom-20 active">
                                                {React.Children.map(this.props.children, c => {
                                                    switch (c.type) {
                                                    case History:
                                                        return React.cloneElement(c, {
                                                            actionObject: ActionHistoryConstants.ACTION_OBJECTS.TITLE,
                                                            id: parseInt(this.props.params.id, 10),
                                                            pagination: true,
                                                            pathname: this.props.location.pathname,
                                                            query: this.props.location.query
                                                        });
                                                    case Summary:
                                                        return React.cloneElement(c, {
                                                            canEditLocalized: false,
                                                            canViewAdditionalInfo: false,
                                                            canViewAlternativeTitles: false,
                                                            canViewLanguages: false,
                                                            canViewLinks: false,
                                                            canViewLocalized: false,
                                                            canViewMasteringInfo: false,
                                                            canViewNonTheatricalRights: false,
                                                            canViewProductionAndDistribution: false,
                                                            canViewReleases: false,
                                                            canViewRights: false,
                                                            canViewSynopsisLogLines: false,
                                                            currentLanguage: this.state.currentLanguage,
                                                            disabled: true,
                                                            disableExpandButton: true,
                                                            mode: this.props.route.mode,
                                                            title: this.state.deletedTitle,
                                                            titleLocalized: this.state.deletedTitle
                                                        });
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                    {modal}
                </div>
            </DocumentTitle>
        );
    }
}

export {
    DeletedTitle
};

export {DeletedTitle as DeletedTitle_BASE};
export default Container.create(DeletedTitle);
